/* ======================================================================== */
/* 25. Cursor */
/* ======================================================================== */
var Cursor = function () {

	var
		$cursor = $('#js-cursor'),
		self = this,
		tl = new TimelineMax();

	this.startLoading = function () {
		window.$document.off('mouseenter mouseleave');
		if ($cursor.length) {
			revealCursor();
		}
	};

	this.finishLoading = function () {
		finishLoading();
		if ($cursor.length) {
			registerEventListeners();
			clearCursor();
		}
	};

	this.mouseX = 0;
	this.mouseY = 0;

	this.update = function () {
		self.mouseX = self.mouseX + pageXOffset;
		self.mouseY = self.mouseY + pageYOffset;
	}

	// don't launch on mobiles
	if (!$cursor.length || Modernizr.touchevents) {
		return;
	}

	var
		tl = new TimelineMax(),
		$follower = $('.cursor__follower'),
		$labelText = $('.cursor__label-text'),
		offset = parseInt(window.$html.css('marginTop'), 10),
		posX = 0,
		posY = 0,
		cursorNoneClass = 'cursor-none',
		trailingDelay = parseFloat(window.theme.animations.cursorAttractionDelay) || 0;

	start();

	function start() {

		var moveEvt = new MouseEvent('mousemove');

		if (!$cursor.length || Modernizr.touchevents) {
			return;
		}

		TweenMax.to($cursor, 1.2, {
			display: 'block',
			autoAlpha: 1,
			y: '-50%',
			x: '-50%',
			ease: Elastic.easeOut.config(1, 0.6),
		});

		TweenMax.to({}, 0.01, {
			repeat: -1,
			onRepeat: function () {

				posX += (self.mouseX - posX);
				posY += (self.mouseY - posY - offset);

				TweenMax.to($cursor, trailingDelay, {
					x: posX,
					y: posY + offset,
				});

			}
		});

		registerEventListeners();
		document.dispatchEvent(moveEvt);

	}

	function startLoading() {

		if (!$cursor.length || Modernizr.touchevents) {
			return;
		}

		tl
			.stop()
			.clear()
			.play()
			.add(function () {
				revealHelper(window.theme.cursorFollower.labels.loading);
			});

	}

	function finishLoading() {

		if (!$cursor.length || Modernizr.touchevents) {
			return;
		}

		tl
			.stop()
			.clear()
			.play()
			.add(function () {
				hideHelper();
			});

	}

	function registerEventListeners() {

		window.$document
			.on('mousemove', function (e) {
				self.mouseX = e.clientX;
				self.mouseY = e.clientY;
			});

		registerEventListener('a, .slider__dot, .slider__arrow, #js-burger, .js-scroll-down, .button, .audio-control', function () {
			revealCursor();
		}, function () {
			clearCursor();
		});

		if (window.theme.cursorFollower.labels.slider !== '') {
			registerEventListener('.slider_draggable, .dialog-lightbox-widget .swiper-container .elementor-lightbox-prevent-close', function () {
				revealHelper(window.theme.cursorFollower.labels.slider);
			}, function () {
				hideHelper();
			});
		}

	}

	function registerEventListener(elements, fEnter, fLeave) {

		window.$document
			.on('mouseenter', elements, function () {
				fEnter();
			})
			.on('mouseleave', elements, function () {
				fLeave();
			});

	}

	function revealHelper(label) {

		tl
			.clear()
			.add(function () {
				$labelText.html(label);
				window.$body.addClass(cursorNoneClass);
			})
			.set($cursor, {
				mixBlendMode: 'normal'
			})
			.to($follower, 0.6, {
				autoAlpha: 1,
				scale: 1,
				ease: Elastic.easeOut.config(1, 0.6),
			})
			.to($labelText, 0.3, {
				y: '0%',
				autoAlpha: 1,
				ease: Power3.easeInOut,
			}, '-=0.6');

	}

	function hideHelper() {

		tl
			.clear()
			.add(function () {
				window.$body.removeClass(cursorNoneClass);
			})
			.to($labelText, 0.3, {
				y: '100%',
				autoAlpha: 0,
				ease: Power3.easeInOut,
			})
			.set($cursor, {
				clearProps: 'mixBlendMode'
			})
			.to($follower, 0.6, {
				autoAlpha: 0.5,
				scale: 0.2,
				ease: Elastic.easeOut.config(1, 0.6),
			}, '-=0.1')
			.add(function () {
				$labelText.html('');
			});
	}

	function revealCursor() {
		tl
			.clear()
			.set($cursor, {
				clearProps: 'mixBlendMode'
			})
			.add([
				TweenMax.to($labelText, 0.3, {
					y: '100%',
					autoAlpha: 0,
					ease: Power3.easeInOut,
				}),
				TweenMax.to($follower, 0.6, {
					autoAlpha: 0.5,
					scale: 0.8,
					ease: Elastic.easeOut.config(1, 0.6),
				})
			]);
	}

	function clearCursor() {
		tl
			.clear()
			.set($cursor, {
				clearProps: 'mixBlendMode'
			})
			.add([
				TweenMax.to($labelText, 0.3, {
					y: '100%',
					autoAlpha: 0,
					ease: Power3.easeInOut,
				}),
				TweenMax.to($follower, 0.6, {
					autoAlpha: 0.5,
					scale: 0.2,
					ease: Elastic.easeOut.config(1, 0.6),
				})
			]);
	}

}
