/* ======================================================================== */
/* 60. setSliderDistortionEffect */
/* ======================================================================== */
function setSliderDistortionEffect(slider, $slider, intensity, aspect = 1.6) {

	if (typeof THREE == 'undefined') {
		return;
	}

	var
		canvas = $slider.find('.slider__canvas').get(0),
		$canvasWrapper = $slider.find('.slider__wrapper-canvas'),
		$wrapper = $slider.find('.swiper-wrapper'),
		displacementImg = $slider.data('transition-displacement-img'),
		images = getSliderImages(),
		speed = slider.params.speed / 1000,
		tl = new TimelineMax();

	slider.params.effect = 'fade';
	slider.params.fadeEffect.crossFade = true;
	window.distortionEffect = new EffectDistortion({
		displacementImage: displacementImg,
		images: images,
		slider: slider,
		aspectRatio: aspect,
		canvas: canvas
	});

	window.distortionEffect.animate();

	slider.on('slideChange', function () {

		tl.clear().to($canvasWrapper, speed, {
				scale: 1.05,
				transformOrigin: 'center center',
				ease: Power3.easeOut
			})
			.to($canvasWrapper, speed * 2, {
				scale: 1,
			});

		window.distortionEffect.change({
			indexFrom: this.previousIndex,
			indexTo: this.activeIndex,
			speedIn: speed,
			intensity: this.activeIndex < this.previousIndex ? intensity * (-1) : intensity,
			easing: Power3.easeInOut
		});

	});

	if (typeof elementor !== 'undefined') {
		TweenMax.set($wrapper, {
			display: 'none'
		});
	} else {
		$wrapper.remove();
	}

	function getSliderImages() {

		var
			src = [],
			current;

		$slider.find('.slider__bg').each(function () {

			current = $(this).attr('data-background');

			if (!current) {
				current = $(this).attr('poster');
			}

			src.push(current);

		});

		return src;

	}

}
