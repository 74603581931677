/* ======================================================================== */
/* 21. audioControl */
/* ======================================================================== */
class AudioControl {

  constructor() {

    this.$control = $('.js-audio-background__control')
    this.$icon = this.$control.find('.js-audio-background__icon');
    this.$label = this.$control.find('.js-audio-background__label');

    this.hiddenClass = 'hidden';
  }

  reveal(yes) {
    if (yes === true) {
      this.$control.removeClass(this.hiddenClass)
    } else {
      this.$control.addClass(this.hiddenClass);
    }
  }

  play() {
    this.$icon.html(window.theme.audio.playing.icon.toString());
    this.$label.html(window.theme.audio.playing.label.toString());
  }

  pause() {
    this.$icon.html(window.theme.audio.paused.icon.toString());
    this.$label.html(window.theme.audio.paused.label.toString());
  }

}
