/* ======================================================================== */
/* 41. SmoothScroll */
/* ======================================================================== */
var SmoothScroll = function () {

	var
		$smoothScroll = $('.js-smooth-scroll'),
		$WPadminBar = $('#wpadminbar');

	if (!$smoothScroll.length || !window.theme.smoothScroll.enabled) {
		return;
	}

	// don't launch in Elementor edit mode
	if (typeof elementor != 'undefined') {
		return;
	}

	// don't launch on mobiles
	if (Modernizr.touchevents && !$smoothScroll.hasClass('js-smooth-scroll_enable-mobile')) {
		return;
	}

	$smoothScroll.addClass('smooth-scroll');

	if (window.theme.smoothScroll.plugins.edgeEasing) {
		Scrollbar.use(window.EdgeEasingPlugin);
	}

	Scrollbar.destroyAll();

	window.SB = Scrollbar.init($smoothScroll[0], window.theme.smoothScroll);

	// Emit native scroll event to window
	if (typeof window.SB !== 'undefined') {

		var scrollEvt = new CustomEvent('scroll');

		window.SB.addListener(function (e) {
			window.pageYOffset = e.offset.y;
			window.pageXOffset = e.offset.x;
			window.dispatchEvent(scrollEvt);
		});

	}

	// prevent double scroll because of the offset created
	// by WordPress admin bar
	if (typeof window.SB !== 'undefined' && $WPadminBar.length) {

		window.$html.css({
			overflow: 'hidden'
		});

	}

	// handle smooth anchor scrolling
	$('a[href^=#]').each(function () {

		var
			$current = $(this),
			$el = $($current.attr('href'));

		if ($el.length) {
			$current.on('click', function () {
				window.SB.scrollIntoView($el.get(0));
			});
		}

	});

}
