/* ======================================================================== */
/* 58. renderSliderCounter */
/* ======================================================================== */
function renderSliderCounter(sliderMain, sliderCounter, slideClass, elTotal, sliderSecondary, addZeros = 2) {

	if ($(sliderMain).length && $(sliderSecondary).length && !$(sliderCounter)) {
		sliderSecondary.controller.control = sliderMain;
		sliderMain.controller.control = sliderSecondary;
	}

	if (!$(sliderMain).length || !$(sliderCounter).length || !$(elTotal).length) {
		return;
	}

	var
		numOfSlides = sliderMain.slides.length,
		startSlides = parseInt(sliderMain.params.slidesPerView, 10),
		prefixTen = '0',
		prefixCurrent = '00',
		prefixTotal = numOfSlides >= 10 ? '0' : '00';

	var counter = new Swiper(sliderCounter, {
		direction: 'vertical',
		simulateTouch: false,
		allowTouchMove: false
	});

	switch (addZeros) {
		case 0:
			prefixCurrent = '';
			prefixTen = '';
			prefixTotal = '';
			break;
		case 1:
			prefixCurrent = '0';
			prefixTen = '';
			prefixTotal = numOfSlides >= 10 ? '' : '0';
			break;
		case 2:
			prefixCurrent = '00';
			prefixTen = '0';
			prefixTotal = numOfSlides >= 10 ? '0' : '00';
			break;
	}

	counter.removeAllSlides();

	for (var index = startSlides; index <= numOfSlides; index++) {

		if (index >= 10) {
			prefixCurrent = prefixTen;
		}

		counter.appendSlide('<div class="swiper-slide"><div class="' + slideClass + '">' + prefixCurrent + index + '</div></div>');

	}

	$(elTotal).html(prefixTotal + numOfSlides);

	sliderMain.controller.control = counter;
	counter.controller.control = sliderMain;

	if ($(sliderSecondary).length) {
		sliderSecondary.controller.control = counter;
		counter.controller.control = sliderSecondary;
	}

}
