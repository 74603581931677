/* ======================================================================== */
/* 1. PJAX Animate Clonned Heading */
/* ======================================================================== */
function PJAXAnimateClonnedHeading(data, $customPositionElement) {

	return new Promise(function (resolve, reject) {

		var
			tl = new TimelineMax(),
			$nextContainer = $(data.next.container),
			$trigger = $(data.trigger),
			$nextContent = $nextContainer.find('.page-wrapper__content'),
			$clonnedHeading = $('.js-text-to-fly.clone'),
			$clonnedHeadingChars = $clonnedHeading.find('.split-text__char.clone'),
			$mastheadHeading = $nextContainer.find('.section-masthead .js-text-to-fly'),
			staggerAmount = 0.15,
			$mastheadHeadingChars,
			$mastheadHeadingWords,
			$mastheadHeadingLines,
			coordinates = [],
			CSSProperties,
			from,
			resolveTiming = $('.section-masthead_big-heading[data-os-animation]').length ? 0.6 : 0.9;

		if (!$mastheadHeading.length) {

			tl.set($nextContent, {
				y: '10vh',
			})
				.to($clonnedHeading, 0.6, {
					autoAlpha: 0,
					display: 'none',
					y: '-100%'
				})
				.to(window.$curtain, 1.2, {
					y: '-100%',
					ease: Expo.easeInOut
				}, '0.6')
				.to($nextContent, 2.4, {
					y: '0vh',
					ease: Expo.easeInOut,
				}, '0')
				.set(window.$curtain, {
					y: '100%',
					display: 'none'
				})
				.set($trigger, {
					autoAlpha: 1
				})
				.add(function () {
					$clonnedHeading.remove();
				})
				.add(function () {
					resolve(true);
				}, '0.8');

			return;

		}

		if (window.theme !== 'undefined') {

			staggerAmount = window.theme.animations.flyingHeadingsStagger || 0.15;

		}

		$mastheadHeadingChars = $mastheadHeading.find('.split-text__char');
		$mastheadHeadingWords = $mastheadHeading.find('.split-text__word');
		$mastheadHeadingLines = $mastheadHeading.find('.split-text__line');
		CSSProperties = $mastheadHeadingChars.css(['text-align', 'font-size', 'line-height', 'color', 'opacity']);
		from = PJAXGetFlyingDirection($clonnedHeading, $mastheadHeading);

		// clear any transforms for the correct
		// position calculation
		TweenMax.set([$mastheadHeadingChars, $mastheadHeadingWords, $mastheadHeadingLines], {
			x: 0,
			y: 0,
			clearProps: 'transform'
		});

		// clear transforms on page content wrapper
		// in case it may be shifted
		TweenMax.set($nextContent, {
			clearProps: 'all'
		});

		$mastheadHeadingChars.each(function (index) {

			var current = $(this).get(0).getBoundingClientRect();

			coordinates[index] = {
				top: current.top,
				left: current.left
			};

		});

		tl
			.add(function () {
				scrollToVeryTop();
			})
			.set($nextContent, {
				y: '10vh',
			})
			.delay(0.2)
			.staggerTo($clonnedHeadingChars, 1.2, {
				position: 'absolute',
				fontSize: CSSProperties['font-size'],
				lineHeight: CSSProperties['line-height'],
				transform: 'none',
				autoAlpha: 1,
				cycle: {
					left: function (index) {

						if (coordinates[index]) {
							return coordinates[index].left + 'px';
						} else {
							$clonnedHeadingChars[index].remove();
							return '0px';
						}

					},
					top: function (index) {

						if (coordinates[index]) {
							return coordinates[index].top + 'px';
						} else {
							$clonnedHeadingChars[index].remove();
							return '0px';
						}

					}
				},
				stagger: distributeByPosition({
					amount: staggerAmount,
					from: from
				}),
				ease: Power3.easeInOut,
			})
			.set([$mastheadHeadingChars, $mastheadHeadingWords, $mastheadHeadingLines], {
				autoAlpha: 1,
				clearProps: 'transform'
			}, '+=0.6')
			.add(function () {
				$clonnedHeading.remove();
			})
			.add(function () {
				if ($mastheadHeading.hasClass('section-masthead__heading-big')) {
					tl.to($clonnedHeadingChars, 1.2, {
						opacity: .3
					}, '0.6');
				}
			}, '0.6')
			.to($clonnedHeadingChars, 1.2, {
				color: CSSProperties['color'],
			}, '0.6')
			.to(window.$curtain, 1.2, {
				y: '-100%',
				ease: Expo.easeInOut
			}, '0.6')
			.to($nextContent, 2.4, {
				y: '0vh',
				onComplete: function () {
					TweenMax.set($nextContent, {
						clearProps: 'all'
					});
				},
				ease: Expo.easeInOut,
			}, '0')
			.set(window.$curtain, {
				y: '100%',
				display: 'none'
			})
			.set($trigger, {
				autoAlpha: 1
			})
			.add(function () {
				resolve(true);
			}, resolveTiming);

	});

}
