/* ======================================================================== */
/* 61. setSliderOverlapEffect */
/* ======================================================================== */
function setSliderOverlapEffect(slider, overlapFactor) {

	var
		i,
		innerOffset,
		innerTranslate,
		background;

	initialSet();

	function initialSet() {

		innerOffset = slider.width * overlapFactor;
		innerTranslate = innerOffset * (-1);
		background = slider.slides[1].querySelector('.slider__bg')

		TweenMax.set(background, {
			y: slider.params.direction == 'vertical' ? innerTranslate + 'px' : '',
			x: slider.params.direction == 'horizontal' ? innerTranslate + 'px' : '',
			transition: slider.params.speed + 'ms',
			force3D: true,
			backfaceVisibility: 'hidden'
		});

	}

	slider
		.on('progress', function () {

			for (i = 0; i < slider.slides.length; i++) {

				innerTranslate = slider.slides[i].progress * innerOffset;

				try {

					TweenMax.set(slider.slides[i].querySelector('.slider__bg'), {
						y: slider.params.direction == 'vertical' ? innerTranslate + 'px' : '',
						x: slider.params.direction == 'horizontal' ? innerTranslate + 'px' : '',
						force3D: true,
						backfaceVisibility: 'hidden'
					});

				} catch (error) {

				}

			}

		})
		.on('setTransition', function (speed) {

			for (i = 0; i < slider.slides.length; i++) {

				try {

					TweenMax.set(slider.slides[i], {
						transition: speed + 'ms',
					});

					TweenMax.set(slider.slides[i].querySelector('.slider__bg'), {
						transition: speed + 'ms',
					});

				} catch (error) {

				}

			}

		});

}
