/* ======================================================================== */
/* 63. setSliderTextTransitions */
/* ======================================================================== */
function setSliderTextTransitions(sliderContent, direction, offset = 40, $heading, $subheading, $description, $link) {

	var
		tl = new TimelineMax(),
		textAlign = $heading.css('text-align'),
		sliderSpeed = sliderContent.params.speed / 1000,
		offsetXNextIn = 0,
		offsetXNextOut = 0,
		offsetYNextIn = 0,
		offsetYNextOut = 0,
		offsetXPrevIn = 0,
		offsetXPrevOut = 0,
		offsetYPrevIn = 0,
		offsetYPrevOut = 0,

		fromNextIn = 'start',
		fromNextOut = 'start',
		fromPrevIn = 'end',
		fromPrevOut = 'end';


	switch (textAlign) {
		case 'left':
			// text align left & slider horizontal
			if (direction == 'horizontal') {

				offsetXNextIn = offset;
				offsetXNextOut = offset * (-1);
				offsetXPrevIn = offset * (-1);
				offsetXPrevOut = offset;

				fromNextOut = 'start';
				fromNextIn = 'start';
				fromPrevOut = 'end';
				fromPrevIn = 'end';

			}
			// text align left & slider vertical
			if (direction == 'vertical') {

				offsetYNextIn = offset;
				offsetYNextOut = offset * (-1);
				offsetYPrevIn = offset * (-1);
				offsetYPrevOut = offset;

				fromNextOut = 'start';
				fromNextIn = 'end';
				fromPrevOut = 'end';
				fromPrevIn = 'start';
			}
			break;
		case 'center':
			// text align center & slider horizontal
			if (direction == 'horizontal') {

				offsetXNextIn = offset;
				offsetXNextOut = offset * (-1);
				offsetXPrevIn = offset * (-1);
				offsetXPrevOut = offset;

				fromNextOut = 'start';
				fromNextIn = 'start';
				fromPrevOut = 'end';
				fromPrevIn = 'end';

			}
			// text align left & slider vertical
			if (direction == 'vertical') {

				offsetYNextIn = offset / 2;
				offsetYNextOut = offset * (-1) / 2;
				offsetYPrevIn = offset * (-1) / 2;
				offsetYPrevOut = offset / 2;

				fromNextOut = 'center';
				fromNextIn = 'center';
				fromPrevOut = 'center';
				fromPrevIn = 'center';
			}
			break;
		case 'right':
			// text align right & slider horizontal
			if (direction == 'horizontal') {

				offsetXNextIn = offset * (-1);
				offsetXNextOut = offset;
				offsetXPrevIn = offset;
				offsetXPrevOut = offset * (-1);

				fromNextOut = 'end';
				fromNextIn = 'end';
				fromPrevOut = 'start';
				fromPrevIn = 'start';

			}
			// text align right & slider vertical
			if (direction == 'vertical') {

				offsetYNextIn = offset * (-1);
				offsetYNextOut = offset;
				offsetYPrevIn = offset;
				offsetYPrevOut = offset * (-1);

				fromNextOut = 'end';
				fromNextIn = 'start';
				fromPrevOut = 'start';
				fromPrevIn = 'end';
			}
			break;
	}

	sliderContent.on('slideChange', function () {

		if (sliderContent.realIndex > sliderContent.previousIndex) {
			slideChangeTransition('next');
		}

		if (sliderContent.realIndex < sliderContent.previousIndex) {
			slideChangeTransition('prev');
		}

	});

	function slideChangeTransition(direction = 'next') {

		var
			directionOutX,
			directionOutY,
			directionOutFrom,
			directionInX,
			directionInY,
			directionInFrom,
			$activeSlide = $(sliderContent.slides[sliderContent.realIndex]),
			$activeHeading = $activeSlide.find($heading),
			$activeSubheading = $activeSlide.find($subheading),
			$activeDescription = $activeSlide.find($description),
			$activeLink = $activeSlide.find($link);

		if (direction === 'next') {

			// next out
			directionOutX = offsetXNextOut;
			directionOutY = offsetYNextOut;
			directionOutFrom = fromNextOut;

			// next in
			directionInX = offsetXNextIn;
			directionInY = offsetYNextIn;
			directionInFrom = fromNextIn;

		}

		if (direction === 'prev') {

			// prev out
			directionOutX = offsetXPrevOut;
			directionOutY = offsetYPrevOut;
			directionOutFrom = fromPrevOut;

			// prev in
			directionInX = offsetXPrevIn;
			directionInY = offsetYPrevIn;
			directionInFrom = fromPrevIn;

		}

		tl.clear();

		$heading.each(function () {

			tl
				.add(hideChars($(this), sliderSpeed / 2, 0.3, Power3.easeInOut, directionOutX, directionOutY, directionOutFrom), '0')
				.add(hideChars($subheading, sliderSpeed / 2, 0.3, Power3.easeInOut, directionOutX / 1.5, directionOutY / 1.5, directionOutFrom), '0')
				.add(hideLines($description, sliderSpeed / 2, 0.04, directionOutY || directionOutX, Power3.easeOut), '0')
				.add(hideButton(), '-=' + sliderSpeed / 2)
				.add(hideChars($(this), 0, 0, Power3.easeInOut, directionInX, directionInY))
				.add(hideChars($subheading, 0, 0, Power3.easeInOut, directionInX / 2, directionInY / 2))
				.add(hideLines($activeDescription, 0, 0, directionInY || directionInX))
				.add(setButton());

		});

		tl
			.add(animateChars($activeHeading, sliderSpeed, 0.3, Power3.easeOut, directionInFrom))
			.add(animateLines($activeDescription, sliderSpeed, 0.04, Power3.easeOut, directionInFrom), '-=' + sliderSpeed)
			.add(animateChars($activeSubheading, sliderSpeed, 0.3, Power3.easeOut, directionInFrom), '-=' + sliderSpeed)
			.add(showButton($activeLink), '-=' + sliderSpeed / 4 * 3);


	}

	function hideButton() {

		var tl = new TimelineMax();

		if (typeof $link != 'undefined' && $link.length) {
			tl.to($link, sliderSpeed / 2, {
				y: (offsetYNextOut || offsetXNextOut) * (-1) / 2 + 'px',
				autoAlpha: 0
			});
		}

		return tl;

	}

	function showButton($activeLink) {

		var tl = new TimelineMax();

		if (typeof $activeLink != 'undefined' && $activeLink.length) {
			tl.to($activeLink, sliderSpeed / 2, {
				y: '0px',
				autoAlpha: 1
			});
		}

		return tl;

	}

	function setButton() {

		var tl = new TimelineMax();

		if (typeof $link != 'undefined' && $link.length) {
			tl.set($link, {
				y: (offsetYNextIn || offsetXNextIn) / 2 + 'px',
				autoAlpha: 0
			});
		}

		return tl;

	}

}
