/* ======================================================================== */
/* 32. Form */
/* ======================================================================== */
var Form = function () {

	var
		INPUT_CLASS = '.input-float__input',
		INPUT_NOT_EMPTY = 'input-float__input_not-empty',
		INPUT_FOCUSED = 'input-float__input_focused';

	floatLabels();
	ajaxForm();

	if (typeof window.theme !== 'undefined' && window.theme.contactForm7.customModals) {
		attachModalsEvents();
	}

	function floatLabels() {

		if (!$(INPUT_CLASS).length) {
			return;
		}

		$(INPUT_CLASS).each(function () {

			var
				$currentField = $(this),
				$currentControlWrap = $currentField.parent('.wpcf7-form-control-wrap');

			// not empty value
			if ($currentField.val()) {
				$currentField.addClass(INPUT_NOT_EMPTY);
				$currentControlWrap.addClass(INPUT_NOT_EMPTY);
				// empty value
			} else {
				$currentField.removeClass([INPUT_FOCUSED, INPUT_NOT_EMPTY]);
				$currentControlWrap.removeClass([INPUT_FOCUSED, INPUT_NOT_EMPTY]);
			}

			// has placeholder & empty value
			if ($currentField.attr('placeholder') && !$currentField.val()) {
				$currentField.addClass(INPUT_NOT_EMPTY);
				$currentControlWrap.addClass(INPUT_NOT_EMPTY);
			}

		});

		window.$document.off('focusin').on('focusin', INPUT_CLASS, function () {

			var
				$currentField = $(this),
				$currentControlWrap = $currentField.parent('.wpcf7-form-control-wrap');

			$currentField.addClass(INPUT_FOCUSED).removeClass(INPUT_NOT_EMPTY);
			$currentControlWrap.addClass(INPUT_FOCUSED).removeClass(INPUT_NOT_EMPTY);

		}).off('focusout').on('focusout', INPUT_CLASS, function () {

			var
				$currentField = $(this),
				$currentControlWrap = $currentField.parent('.wpcf7-form-control-wrap');

			// not empty value
			if ($currentField.val()) {
				$currentField.removeClass(INPUT_FOCUSED).addClass(INPUT_NOT_EMPTY);
				$currentControlWrap.removeClass(INPUT_FOCUSED).addClass(INPUT_NOT_EMPTY);
			} else {
				// has placeholder & empty value
				if ($currentField.attr('placeholder')) {
					$currentField.addClass(INPUT_NOT_EMPTY);
					$currentControlWrap.addClass(INPUT_NOT_EMPTY);
				}
				$currentField.removeClass(INPUT_FOCUSED);
				$currentControlWrap.removeClass(INPUT_FOCUSED);

			}

		});

	}

	function ajaxForm() {

		var $form = $('.js-ajax-form');

		if (!$form.length) {
			return;
		}

		$form.validate({
			errorElement: 'span',
			errorPlacement: function (error, element) {
				error.appendTo(element.parent()).addClass('form__error');
			},
			submitHandler: function (form) {
				ajaxSubmit(form);
			}
		});

		function ajaxSubmit(form) {

			$.ajax({
				type: $form.attr('method'),
				url: $form.attr('action'),
				data: $form.serialize()
			}).done(function () {
				alert($form.attr('data-message-success'));
				$form.trigger('reset');
				floatLabels();
			}).fail(function () {
				alert($form.attr('data-message-error'));
			});
		}

	}

	function attachModalsEvents() {
		window.$document.off('wpcf7submit').on('wpcf7submit', function (e) {

			var $modal = $('#modalContactForm7');

			$modal.modal('dispose').remove();

			if (e.detail.apiResponse.status === 'mail_sent') {

				createModalTemplate({
					icon: 'icon-success.svg',
					message: e.detail.apiResponse.message,
					onHide: function () {
						$(e.srcElement).find(INPUT_CLASS).parent().val('').removeClass(INPUT_FOCUSED).removeClass(INPUT_NOT_EMPTY);
					}
				});
			}

			if (e.detail.apiResponse.status === 'mail_failed') {
				createModalTemplate({
					icon: 'icon-error.svg',
					message: e.detail.apiResponse.message
				});
			}

		});
	}

	function createModalTemplate({
		icon,
		message,
		onHide
	}) {

		window.$body.append(`
			<div class="modal" id="modalContactForm7">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content radius-img">
						<div class="modal__close" data-dismiss="modal"><img src="${window.theme.themeURL}/img/general/icon-close.svg"/></div>
							<header class="text-center mb-3">
								<img src="${window.theme.themeURL}/img/general/${icon}" width="80px" height="80px" alt=""/>
								<p class="modal__message h4"><strong>${message}</strong></p>
							</header>
							<button type="button" class="button button_solid button_accent-secondary-2 button_fullwidth" data-dismiss="modal">OK</button>
					</div>
				</div>
			</div>
		`);
		var $modal = $('#modalContactForm7');

		$modal.modal('show');
		$modal.on('hidden.bs.modal', function () {
			$modal.modal('dispose').remove();
			if (typeof onHide === 'function') {
				onHide();
			}
		});

	}

}
