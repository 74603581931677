/* ======================================================================== */
/* 20. AudioBackground */
/* ======================================================================== */
class AudioBackground {

  constructor($scope) {
    this.$target = $scope.find('#js-audio-background');
    this.$options = $scope.find('#js-audio-background__options');
    this.controller = new AudioControl();
    this.el = this.$target.get(0);
    this.targetVolume = this.$target.data('volume') || 100;
    this.tl = new TimelineMax();
    this.el.volume = 0;
    this.el.muted = false;
    this.bindEvents();
  }

  run() {

    if (this.isAudioSrc() && !this.isAudioOff()) {

      this.controller.reveal(true);

      if (this.el.autoplay === true) {
        this.controller.play();
        this.fadeIn();
      } else {
        this.controller.pause();
      }

    }

  }

  isAudioOff() {
    return this.$options.attr('data-options') === 'music_off';
  }

  isAudioSrc() {
    return this.$target.attr('src').length > 0;
  }

  bindEvents() {
    this.controller.$control.on('click', () => {
      if (!this.isAudioSrc()) {
        return false;
      }

      if (this.el.paused === true) {
        this.el.volume = 0;
        this.el.play();
        this.fadeIn();
        this.controller.play();
      } else {
        this.fadeOut().then(() => {
          this.el.pause();
        });
        this.controller.pause();
      }
    });

    this.el.addEventListener('play', () => {
      this.controller.play();
    });
    this.el.addEventListener('pause', () => {
      this.controller.pause();
    });
  }

  fadeIn() {

    return new Promise((resolve) => {

      const volume = {
        val: 0
      };

      if (this.el.play !== 'undefined') {
        this.el.play().then(() => {
          this.tl
            .clear()
            .to(volume, 3, {
              val: this.targetVolume,
              ease: Power3.easeInOut,
              onUpdate: () => {
                let value = parseFloat((volume.val) / 100);
                if (value >= 0 && value <= this.targetVolume / 100) {
                  this.el.volume = value;
                }
              },
              onComplete: () => {
                resolve(true);
              }
            });
        }).catch(() => {
          resolve(true);
        });
      }

    });
  }

  fadeOut() {

    return new Promise((resolve) => {

      const volume = {
        val: 0
      };

      this.tl
        .clear()
        .to(volume, 1, {
          val: this.targetVolume,
          ease: Power3.easeInOut,
          onUpdate: () => {
            let value = parseFloat((this.targetVolume - volume.val) / 100);
            if (value >= 0 && value <= this.targetVolume / 100) {
              this.el.volume = value;
            }
          },
          onComplete: () => {
            this.el.pause();
            resolve(true);
          }
        });

    });

  }

}
