/* ======================================================================== */
/* 15. PJAX Update Language Switcher */
/* ======================================================================== */
function PJAXUpdateLanguageSwitcher(data) {

	return new Promise(function (resolve, reject) {

		var $currentSwitcher = $('.lang-switcher');

		if (!$currentSwitcher.length) {
			resolve(true);
			return;
		}

		var
			rawHTML = $.parseHTML(data.next.html),
			$newSwitcher = $(rawHTML).find('.lang-switcher');

		$currentSwitcher.replaceWith($newSwitcher);

		resolve(true);

	});

}
