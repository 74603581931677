/* ======================================================================== */
/* 65. SliderImages */
/* ======================================================================== */
var SliderImages = function ($scope) {

	var $slider = $scope.find('.js-slider-images');

	if (!$slider.length) {
		return;
	}

	$slider.each(function () {

		var
			$current = $(this),
			$sliderCaptions = $scope.find('.js-slider-images__captions'),
			sliderCaptions,
			counterZeros = $current.data('counter-zeros'),
			lg = window.elementorFrontend ? window.elementorFrontend.config.breakpoints.lg - 1 : 1024,
			md = window.elementorFrontend ? window.elementorFrontend.config.breakpoints.md - 1 : 767;

		var slider = new Swiper($current, {
			autoHeight: $current.data('auto-height') || true,
			speed: $current.data('speed') || 1200,
			preloadImages: false,
			lazy: {
				loadPrevNext: true,
				loadPrevNextAmount: 3,
				loadOnTransitionStart: true
			},
			touchRatio: $current.data('touch-ratio') || 3,
			observer: true,
			watchSlidesProgress: true,
			watchSlidesVisibility: true,
			centeredSlides: $current.data('centered-slides') || false,
			slidesPerView: $current.data('slides-per-view') || 1.5,
			slidesPerGroup: 1, // compatibility with Swiper 5.x
			autoplay: {
				disableOnInteraction: false,
				enabled: $current.data('autoplay-enabled') || false,
				delay: $current.data('autoplay-delay') || 6000,
			},
			spaceBetween: $current.data('space-between') || 60,
			pagination: {
				el: '.js-slider-images__dots',
				type: 'bullets',
				bulletElement: 'div',
				clickable: true,
				bulletClass: 'slider__dot',
				bulletActiveClass: 'slider__dot_active'
			},
			navigation: {
				nextEl: '.js-slider-images__next',
				prevEl: '.js-slider-images__prev',
			},
			breakpointsInverse: true // compatibility with both Swiper 4.x and 5.x
		});

		slider.params.breakpoints = {
			0: {
				slidesPerView: $current.data('slides-per-view-mobile') || 1.16,
				spaceBetween: $current.data('space-between-mobile') || 10,
				centeredSlides: $current.data('centered-slides-mobile') || true,
			},
			[md]: {
				slidesPerView: $current.data('slides-per-view-tablet') || 1.33,
				spaceBetween: $current.data('space-between-tablet') || 20,
				centeredSlides: $current.data('centered-slides-tablet') || true,
			},
			[lg]: {
				centeredSlides: $current.data('centered-slides') || false,
				slidesPerView: $current.data('slides-per-view') || 1.5,
				spaceBetween: $current.data('space-between') || 60,
			}
		};

		slider.update();

		if ($sliderCaptions.length) {

			sliderCaptions = new Swiper($sliderCaptions, {
				autoHeight: true,
				direction: 'vertical',
				fadeEffect: {
					crossFade: true
				},
				speed: $current.data('speed') || 1200,
				allowTouchMove: false,
				watchSlidesProgress: true
			});

		}

		slider
			// update height after images are loaded
			.on('lazyImageReady', function () {
				slider.update();
			})
			// zoom images effect
			.on('touchStart', function () {
				$slider.addClass('slider-images_touched');
			})
			.on('touchEnd', function () {
				$slider.removeClass('slider-images_touched');
			});

		// cursor follower position adjustment
		if (typeof window.InteractiveCursor !== 'undefined') {
			slider.on('touchMove', function (e) {

				window.InteractiveCursor.mouseX = e.clientX;
				window.InteractiveCursor.mouseY = e.clientY;
			});
		}

		$slider.imagesLoaded().progress({
			background: true
		}, function (e) {
			setTimeout(function () {
				slider.update();
			}, 300);
		});

		renderSliderDots(slider, $scope.find('.js-slider-images__dots'));
		renderSliderCounter(
			slider,
			$scope.find('.js-slider-images__counter-current'),
			'',
			$scope.find('.js-slider-images__counter-total'),
			sliderCaptions,
			counterZeros
		);

	});

}
