/* ======================================================================== */
/* 85. elementor */
/* ======================================================================== */
/**
 * Elementor Preview
 */
if (typeof elementor !== 'undefined') {

	/**
	 * Reload Elementor Preview
	 */
	function reloadPreview(openedPageAfter, openedTabAfter, openedSectionAfter) {

		elementor.saver.update({
			onSuccess: function () {

				elementor.reloadPreview();
				elementor.once('preview:loaded', function () {

					if (openedPageAfter) {
						elementor.getPanelView().setPage(openedPageAfter);
					}

					if (openedTabAfter) {
						elementor.getPanelView().getCurrentPageView().activeTab = openedTabAfter;
					}

					if (openedSectionAfter) {
						elementor.getPanelView().getCurrentPageView().activateSection(openedSectionAfter);

					}

					elementor.getPanelView().getCurrentPageView().render();

				});

			}
		});

	}

	window.$window.on('elementor/frontend/init', function () {

		elementorFrontend.hooks.addAction('frontend/element_ready/global', function ($scope) {
			doSplitText($scope);
			setLines();
			setChars();
			window.PagePreloader.finish();
			window.$body.removeClass('cursor-progress');
			new SectionMasthead(window.$document);
			initComponents($scope);
			window.SMController.update(true);
			window.SMController.enabled(true);
		});

		var
			paddingTopClasses = [
				'section_pt-xsmall',
				'section_pt-small',
				'section_pt',
				'section_pt-large',
				'section_pt-xlarge'
			],
			paddingBottomClasses = [
				'section_pb-xsmall',
				'section_pb-small',
				'section_pb',
				'section_pb-large',
				'section_pb-xlarge'
			],
			marginTopClasses = [
				'section_mt-xsmall',
				'section_mt-small',
				'section_mt',
				'section_mt-large',
				'section_mt-xlarge'
			],
			marginBottomClasses = [
				'section_mb-xsmall',
				'section_mb-small',
				'section_mb',
				'section_mb-large',
				'section_mb-xlarge'
			],
			colorThemes = [
				'bg-white',
				'bg-off-white',
				'bg-light',
				'bg-black',
				'bg-dark',
				'bg-dark-2',
				'bg-dark-3',
				'bg-accent-primary-1',
				'bg-accent-primary-2',
				'bg-accent-primary-3',
				'bg-accent-primary-4',
				'bg-accent-secondary-1',
				'bg-accent-secondary-2',
				'bg-accent-secondary-3',
				'bg-accent-secondary-4'
			],
			alignmentClasses = [
				'text-left',
				'text-center',
				'text-right'
			],
			containerClasses = [
				'section_w-container-left',
				'container',
				'section_w-container-right'
			],
			innerClasses = [
				'section-masthead__inner_background-left',
				'section_w-container-center',
				'section-masthead__inner_background-right'
			],
			typographyClasses = [
				'h1',
				'h2',
				'h3',
				'h4',
				'h5',
				'h6',
				'h1',
				'text-xl',
				'text-xxl'
			],
			headerColorClasses = [
				'header_color-white',
				'header_color-black',
			],
			stringColorThemes = colorThemes.join(' '),
			stringAlignmentClasses = alignmentClasses.join(' '),
			stringContainerClasses = containerClasses.join(' '),
			stringInnerClasses = innerClasses.join(' '),
			stringTypographyClasses = typographyClasses.join(' '),
			stringHeaderClasses = headerColorClasses.join(' '),
			stringPaddingTopClasses = paddingTopClasses.join(' '),
			stringPaddingBottomClasses = paddingBottomClasses.join(' '),
			stringMarginTopClasses = marginTopClasses.join(' '),
			stringMarginBottomClasses = marginBottomClasses.join(' ');

		var
			$masthead = $('.section-masthead'),
			$mastheadImage = $masthead.find('.section-image'),
			$mastheadCurtain = $masthead.find('.section-masthead__curtain'),
			$mastheadInner = $masthead.find('.section-masthead__inner'),
			$mastheadHeading = $masthead.find('.section-masthead__heading'),
			$mastheadText = $masthead.find('.section-masthead__text'),
			$header = $('.header'),
			$footer = $('.footer'),
			$footerAreaPrimary = $('.footer__area-primary'),
			$footerAreaSecondary = $('.footer__area-secondary'),
			$portfolioNav = $('.section-nav-projects');

		/**
		 * Page Masthead Style
		 */
		elementor.settings.page.addChangeCallback('page_masthead_style', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_masthead_section');

		});

		/**
		 * Page Masthead Content Alignment
		 */
		elementor.settings.page.addChangeCallback('page_masthead_content_alignment', function (newval) {

			$masthead.removeClass(stringAlignmentClasses).addClass(newval);

			TweenMax.set($masthead.find('.split-text__line'), {
				clearProps: 'text-align'
			});

		});

		/**
		 * Page Masthead Content position
		 */
		elementor.settings.page.addChangeCallback('page_masthead_content_position', function (newval) {

			$mastheadInner.removeClass(stringInnerClasses).addClass(newval);

		});

		/**
		 * Page Masthead Image Position
		 */
		elementor.settings.page.addChangeCallback('page_masthead_image_position', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_masthead_section');

		});

		/**
		 * Page Masthead Image Alignment
		 */
		elementor.settings.page.addChangeCallback('page_masthead_image_alignment', function (newval) {

			$mastheadImage.removeClass(stringContainerClasses).addClass(newval);

		});

		/**
		 * Page Masthead Content Background Enabled
		 */
		elementor.settings.page.addChangeCallback('page_masthead_content_enable_background', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_masthead_section');

		});

		/**
		 * Page Masthead Content Background
		 */
		elementor.settings.page.addChangeCallback('page_masthead_content_background', function (newval) {

			$masthead.removeClass(stringColorThemes).addClass(newval);
			$mastheadCurtain.removeClass(stringColorThemes).addClass(newval);

		});

		/**
		 * Page Masthead Content Container
		 */
		elementor.settings.page.addChangeCallback('page_masthead_content_container', function (newval) {

			$mastheadInner.removeClass('container-fluid container section-masthead__inner_background').addClass(newval);

		});

		/**
		 * Page Masthead Paddings & Margins
		 */
		elementor.settings.page.addChangeCallback('page_masthead_pt', function (newval) {
			$masthead.removeClass(stringPaddingTopClasses).addClass(newval);
		});
		elementor.settings.page.addChangeCallback('page_masthead_pb', function (newval) {
			$masthead.removeClass(stringPaddingBottomClasses).addClass(newval);
		});
		elementor.settings.page.addChangeCallback('page_masthead_mt', function (newval) {
			$masthead.removeClass(stringMarginTopClasses).addClass(newval);
		});
		elementor.settings.page.addChangeCallback('page_masthead_mb', function (newval) {
			$masthead.removeClass(stringMarginBottomClasses).addClass(newval);
		});

		/**
		 * Page Masthead Main Heading Size
		 */
		elementor.settings.page.addChangeCallback('page_masthead_heading_size', function (newval) {

			$mastheadHeading.removeClass(stringTypographyClasses).addClass(newval);

		});

		/**
		 * Page Masthead Main Heading Weight
		 */
		elementor.settings.page.addChangeCallback('page_masthead_heading_weight', function (newval) {

			$mastheadHeading.removeClass('heading-light heading-bold').addClass(newval);

		});

		/**
		 * Page Masthead Subheading HTML Tag
		 */
		elementor.settings.page.addChangeCallback('page_masthead_subheading_tag', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_masthead_section');

		});

		/**
		 * Page Masthead Subheading Size
		 */
		elementor.settings.page.addChangeCallback('page_masthead_subheading_size', function (newval) {

			$mastheadText.removeClass(stringTypographyClasses).addClass(newval);

		});

		/**
		 * Page Masthead Subheading Weight
		 */
		elementor.settings.page.addChangeCallback('page_masthead_subheading_weight', function (newval) {

			$mastheadText.removeClass('heading-light heading-bold').addClass(newval);

		});

		/**
		 * Page Masthead Category
		 */
		elementor.settings.page.addChangeCallback('page_masthead_category', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_masthead_section');

		});

		/**
		 * Page Masthead Headline
		 */
		elementor.settings.page.addChangeCallback('page_masthead_headline', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_masthead_section');

		});

		/**
		 * Page Masthead Scroll Down
		 */
		elementor.settings.page.addChangeCallback('page_masthead_scroll_down', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_masthead_section');

		});

		/**
		 * Page Header Settings
		 */
		elementor.settings.page.addChangeCallback('page_header_settings', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_header_section');

		});

		/**
		 * Page Header Main Color Theme
		 */
		elementor.settings.page.addChangeCallback('page_header_main_theme', function (newval) {

			$header.removeClass(stringHeaderClasses).addClass(newval);

		});

		/**
		 * Page Main Logo to Display
		 */
		elementor.settings.page.addChangeCallback('page_header_main_logo', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_header_section');

		});

		/**
		 * Page Sticky Logo to Display
		 */
		elementor.settings.page.addChangeCallback('page_header_sticky_theme', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_header_section');

		});

		/**
		 * Page Sticky Logo to Display
		 */
		elementor.settings.page.addChangeCallback('page_header_sticky_logo', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_header_section');

		});

		/**
		 * Page Sticky Logo to Display
		 */
		elementor.settings.page.addChangeCallback('page_header_overlay_menu_theme', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_header_section');

		});

		/**
		 * Page Portfolio Nav Theme
		 */
		elementor.settings.page.addChangeCallback('page_portfolio_nav_theme', function (newval) {

			$portfolioNav.removeClass(stringColorThemes).addClass(newval);

		});

		/**
		 * Page Footer Settings
		 */
		elementor.settings.page.addChangeCallback('page_footer_settings', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_footer_section');

		});

		/**
		 * Page Footer Hide
		 */
		elementor.settings.page.addChangeCallback('page_footer_hide', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_footer_section');

		});

		/**
		 * Page Footer Color Theme
		 */
		elementor.settings.page.addChangeCallback('page_footer_theme', function (newval) {

			$footer.removeClass(stringColorThemes).addClass(newval);

		});

		/**
		 * Page Footer Logo
		 */
		elementor.settings.page.addChangeCallback('page_footer_logo', function (newval) {

			$footer.attr('data-logo', newval);

		});

		/**
		 * Page Footer Border Primary Area
		 */
		elementor.settings.page.addChangeCallback('page_enable_footer_border_primary', function (newval) {

			$footerAreaPrimary.removeClass('footer__area-border');

			if (newval) {
				$footerAreaPrimary.addClass('footer__area-border');
			}

		});

		/**
		 * Page Footer Border Secondary Area
		 */
		elementor.settings.page.addChangeCallback('page_enable_footer_border_secondary', function (newval) {

			$footerAreaSecondary.removeClass('footer__area-border');

			if (newval) {
				$footerAreaSecondary.addClass('footer__area-border');
			}

		});

		/**
		 * Page Transition Settings
		 */
		elementor.settings.page.addChangeCallback('page_transition_settings', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_transition_section');

		});

		/**
		 * Page Transition AJAX Curtain Color
		 */
		elementor.settings.page.addChangeCallback('page_ajax_curtain_color', function (newval) {

			reloadPreview('page_settings', 'settings', 'page_transition_section');

		});

	});

}
