/* ======================================================================== */
/* 82. fixScrollBar */
/* ======================================================================== */
function fixScrollBar() {

	var
		fixClass = 'body_has-scrollbar';

	/**
	 * Initial set
	 */
	createStyleElement();
	calculateScrollbar();

	/**
	 * Resize handling (with debounce)
	 */
	$window.on('resize', debounce(function () {
		calculateScrollbar();
	}, 250));

	/**
	 * Prevent page from jumping during scroll lock/unlock
	 */
	function calculateScrollbar() {

		if (window.innerWidth > window.$body[0].clientWidth + 5) {
			window.$body.addClass(fixClass);
			$('#cassio-fix-scroll').html(':root { --fix-scroll-bar-px: ' + (window.innerWidth - window.$body[0].clientWidth) + 'px; }');
		} else {
			window.$body.removeClass(fixClass);
		}


	}

	function createStyleElement() {

		if (!$('#cassio-fix-scroll').length) {
			$('head').append('<style id=\"cassio-fix-scroll\"></style>');
		}

	}

}
