/* ======================================================================== */
/* 44. lockScroll */
/* ======================================================================== */
function lockScroll(lock) {

	var LOCK_CLASS = 'body_lock-scroll';

	if (lock) {

		if (typeof window.SB !== 'undefined') {

			window.SB.updatePluginOptions('lockscroll', {
				lock: true
			});

		}

		window.$body.addClass(LOCK_CLASS);

	}

	if (!lock) {

		window.$body.removeClass(LOCK_CLASS);

		if (typeof window.SB !== 'undefined') {

			window.SB.updatePluginOptions('lockscroll', {
				lock: false
			});

		}

	}

}
