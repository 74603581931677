/* ======================================================================== */
/* 29. FigurePortfolioHover */
/* ======================================================================== */
var FigurePortfolioHover = function ($target) {

	var
		tl,
		$curtain,
		$category,
		$heading,
		$icon,
		$imgWrapper;

	if (!$target.length) {
		return;
	}

	tl = new TimelineMax();
	$curtain = $target.find('.figure-portfolio__curtain');
	$category = $target.find('.figure-portfolio__category');
	$heading = $target.find('.figure-portfolio__heading');
	$imgWrapper = $target.find('.figure-portfolio__wrapper-img-zoom');
	$icon = $target.find('.figure-portfolio__icon');

	hideChars($heading, 0, 0, Power3.easeOut, 50, 0, 'end');

	TweenMax.set($category, {
		autoAlpha: 0,
		y: '-40px'
	});

	TweenMax.set($icon, {
		autoAlpha: 0,
		x: '40px'
	});

	$target
		.on('mouseenter touchstart', function () {

			tl
				.clear()
				.to($curtain, 0.6, {
					y: '0%',
					skewY: '-5deg',
					ease: Power3.easeInOut
				})
				.to($imgWrapper, 0.6, {
					y: '-40px',
					ease: Power3.easeInOut,
					transition: 'none'
				}, '0')
				.add(animateChars($heading, 0.6, 0.2, Power3.easeOut), '0.2')
				.to($category, 0.6, {
					autoAlpha: 1,
					y: '0px',
					ease: Power3.easeOut
				}, '0.2')
				.to($icon, 0.6, {
					autoAlpha: 1,
					x: '0px',
					ease: Power3.easeOut
				}, '0.4');

		})
		.on('mouseleave touchend', function () {

			tl
				.clear()
				.add(hideChars($heading, 0.6, 0.2, Power3.easeOut, 50, 0, 'end'), '0')
				.to($category, 0.6, {
					autoAlpha: 0,
					y: '-40px',
					ease: Power3.easeOut
				}, '0')
				.to($icon, 0.6, {
					autoAlpha: 0,
					x: '40px',
					ease: Power3.easeOut
				}, '0.2')
				.to($curtain, 0.6, {
					y: '100%',
					skewY: '0deg',
					ease: Power3.easeOut,
				}, '0.3')
				.to($imgWrapper, 0.6, {
					y: '0px',
					ease: Power3.easeOut,
					transition: 'none'
				}, '0.3');


		});

}
