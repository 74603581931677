/* ======================================================================== */
/* 18. PJAX Wait Container Images */
/* ======================================================================== */
function PJAXWaitContainerImages(data) {

	return new Promise(function (resolve, reject) {

		var
			$nextContainer = $(data.next.container),
			$nextMasthead = $nextContainer.find('.section-masthead');

		/**
		 * We can't wait infinitely for the images
		 * so let's proceed further anyway
		 */
		setTimeout(function () {
			resolve(true);
		}, 3000);

		$nextMasthead.imagesLoaded().always({
			background: true
		}, function () {

			/**
			 * small delay to avoid any problems
			 * with masthead image size calculation
			 */
			setTimeout(function () {
				resolve(true);
			}, 150);

		});

	});

}
