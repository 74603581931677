/* ======================================================================== */
/* 54. SectionPortfolio */
/* ======================================================================== */
var SectionPortfolio = function ($scope) {

	var
		$target = $scope.find('.section-portfolio'),
		$filter,
		$grid,
		$figurePortfolioHover,
		$figurePortfolio,
		$filterItems,
		$filterLine,
		$animTarget,
		colsDesktop,
		colsTablet,
		colsMobile,
		lg,
		md,
		cols,
		FilterPortfolio,
		GridPortfolio;

	if (!$target.length) {
		return;
	}

	$animTarget = $scope.find('.section-portfolio[data-os-animation]');
	$figurePortfolioHover = $target.find('.figure-portfolio-item_hover');
	$figurePortfolio = $animTarget.find('.figure-portfolio[data-os-animation]');
	$filter = $target.find('.js-filter');
	$filterItems = $animTarget.find('.filter__item > *');
	$filterLine = $animTarget.find('.filter__underline');
	$grid = $target.find('.js-grid');
	colsDesktop = parseInt($grid.data('grid-columns'), 10) || -1;
	colsTablet = parseInt($grid.data('grid-columns-tablet'), 10) || -1;
	colsMobile = parseInt($grid.data('grid-columns-mobile'), 10) || -1;
	lg = window.elementorFrontend ? window.elementorFrontend.config.breakpoints.lg - 1 : 1024;
	md = window.elementorFrontend ? window.elementorFrontend.config.breakpoints.md - 1 : 767;
	cols = colsDesktop;

	if (Modernizr.mq('(max-width: ' + lg + 'px)')) {
		cols = colsTablet;
	}

	if (Modernizr.mq('(max-width: ' + md + 'px)')) {
		cols = colsMobile;
	}

	bindGridFilter();
	animateHover();
	prepare();
	animate();

	function bindGridFilter() {

		if (!$filter.length || !$grid.length) {
			return;
		}

		var scrollEvent = new Event('scroll');

		FilterPortfolio = new Filter($scope, $filter);
		GridPortfolio = new Grid($grid);

		if ($filter.length) {

			FilterPortfolio.setActiveItem(0);

			FilterPortfolio.$items.on('click', function (e) {

				e.preventDefault();

				var filterBy = $(this).data('filter');

				if ($grid.hasClass('grid_fancy')) {
					if (filterBy !== '*') {
						$grid.addClass('grid_fancy-disable');
					} else {
						$grid.removeClass('grid_fancy-disable');
					}
				}

				GridPortfolio.isotope({
					filter: filterBy
				});

				// update scroll to trigger reveal animation & lazy load
				GridPortfolio.on('arrangeComplete', function () {
					window.dispatchEvent(scrollEvent);
				});

			});

		}

		GridPortfolio.isotope({
			filter: '*'
		});

	}

	function animateHover() {

		$figurePortfolioHover.each(function () {

			var $current = $(this);

			new FigurePortfolioHover($current);

		});

	}

	function prepare() {
		TweenMax.set($filterItems, {
			autoAlpha: 0,
			y: '30px'
		});
		TweenMax.set($filterLine, {
			autoAlpha: 0
		});
	}

	function animate() {

		var tl = new TimelineMax();

		tl
			.delay(0.1)
			.add([
				TweenMax.staggerTo($filterItems, 0.9, {
					autoAlpha: 1,
					y: '0px',
					ease: Power3.easeOut
				}, 0.1),
				TweenMax.to($filterLine, 0.9, {
					autoAlpha: 1
				})
			]);

		for (var index = 0; index < $figurePortfolio.length; index = index + cols) {

			var
				$array = $figurePortfolio.slice(index, index + cols),
				currentDelay = 0.1;

			$array.each(function (index) {

				var $current = $(this);

				new FigurePortfolioAnimation($current, currentDelay);

				currentDelay += 0.1;


			});


		}

		createOSScene($animTarget, tl);

	}

}
