/* ======================================================================== */
/* 64. sliderFullscreen */
/* ======================================================================== */
var SliderFullScreen = function ($slider) {

	if (!$slider.length) {
		return;
	}

	var
		$heading = $slider.find('.slider__heading'),
		$subheading = $slider.find('.slider__subheading'),
		$description = $slider.find('.slider__text'),
		$link = $slider.find('.slider-fullscreen__wrapper-button'),
		$sliderImg = $slider.find('.js-slider-fullscreen__images'),
		$sliderContent = $slider.find('.js-slider-fullscreen__content'),
		$inner = $slider.find('.slider__images-slide-inner'),
		sliderSpeed = $sliderImg.data('speed') || 1200,
		effectIntensity = $sliderImg.data('transition-effect-intensity') || 0,
		$dots = $slider.find('.js-slider-dots'),
		$counterCurrent = $slider.find('.js-slider-fullscreen__counter-current'),
		$counterTotal = $slider.find('.js-slider-fullscreen__counter-total'),
		counterZeros = $sliderImg.data('counter-zeros');


	var sliderImg = new Swiper($sliderImg, {
		direction: $sliderImg.data('direction') || 'vertical',
		preloadImages: true,
		updateOnImagesReady: true,
		slidesPerView: 1,
		slidesPerGroup: 1, // compatibility with Swiper 5.x
		lazy: {
			loadPrevNextAmount: 6,
			loadPrevNext: true,
			loadOnTransitionStart: true
		},
		speed: sliderSpeed,
		allowTouchMove: true,
		simulateTouch: false,
		touchRatio: $sliderImg.data('direction') == 'vertical' ? 2.5 : 1.1,
		watchSlidesProgress: true,
		keyboard: {
			enabled: $sliderImg.data('keyboard-enabled') || false
		},
	});

	var sliderContent = new Swiper($sliderContent, {
		direction: $sliderImg.data('direction') || 'vertical',
		autoHeight: true,
		effect: 'fade',
		virtualTranslate: true,
		slidesPerView: 1,
		slidesPerGroup: 1, // compatibility with Swiper 5.x
		fadeEffect: {
			crossFade: false
		},
		autoplay: {
			disableOnInteraction: false,
			enabled: $sliderImg.data('autoplay-enabled') || false,
			delay: $sliderImg.data('autoplay-delay') || 6000,
		},
		mousewheel: $sliderImg.data('mousewheel-enabled') ? {
			eventsTarged: $sliderImg.data('mousewheel-target') || '.page-wrapper',
			releaseOnEdges: true,
		} : false,
		navigation: {
			nextEl: '.js-slider-fullscreen__next',
			prevEl: '.js-slider-fullscreen__prev',
		},
		speed: $sliderImg.data('speed') || 1200,
		allowTouchMove: true,
		simulateTouch: false,
		touchRatio: $sliderImg.data('direction') == 'vertical' ? 2.5 : 1.1,
		pagination: {
			el: '.js-slider-dots',
			type: 'bullets',
			bulletElement: 'div',
			clickable: true,
			bulletClass: 'slider__dot',
			bulletActiveClass: 'slider__dot_active'
		},
	});

	TweenMax.set($inner, {
		transitionDelay: sliderSpeed + 'ms' || '1200ms'
	});

	renderSliderDots(sliderContent, $dots);
	renderSliderCounter(
		sliderContent,
		$counterCurrent,
		'',
		$counterTotal,
		sliderImg,
		counterZeros
	);

	sliderImg.on('slideChange', function () {
		sliderContent.slideTo(sliderImg.realIndex);
	});

	setSliderTextTransitions(sliderContent, sliderImg.params.direction, 25, $heading, $subheading, $description, $link);

	if ($sliderImg.data('transition-effect') == 'enter_leave') {

		setSliderOverlapEffect(sliderImg, effectIntensity);

	}

	if ($sliderImg.data('transition-effect') == 'distortion') {

		var aspectRatio = parseFloat($slider.find('[data-aspect-ratio]').data('aspect-ratio')) || 1.7777;

		sliderImg.params.preloadImages = false;
		sliderImg.params.lazy = false;
		setSliderDistortionEffect(sliderImg, $sliderImg, effectIntensity, aspectRatio);

	}

	return sliderContent;

}
