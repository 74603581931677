/* ======================================================================== */
/* 51. SectionGrid */
/* ======================================================================== */
var SectionGrid = function ($scope) {

	var
		$target = $scope.find('.section-grid[data-os-animation]'),
		$captions,
		$images,
		$items;

	if (!$target.length) {
		return;
	}

	$captions = $target.find('.figure-image__wrapper-caption');
	$items = $target.find('.figure-image');
	$images = $target.find('.figure-image__wrapper-img');

	prepare();
	animate();

	function prepare() {

		TweenMax.set($images, {
			scaleY: 1.5,
			y: '33%',
			transformOrigin: 'top center',
			autoAlpha: 0.01,
		});

		TweenMax.set($captions, {
			autoAlpha: 0,
		});

		TweenMax.set($target, {
			autoAlpha: 1
		});

	}

	function animate() {

		var
			colsDesktop = parseInt($target.data('grid-columns'), 10),
			colsTablet = parseInt($target.data('grid-columns-tablet'), 10),
			colsMobile = parseInt($target.data('grid-columns-mobile'), 10),
			lg = window.elementorFrontend ? window.elementorFrontend.config.breakpoints.lg - 1 : 1024,
			md = window.elementorFrontend ? window.elementorFrontend.config.breakpoints.md - 1 : 767,
			cols = colsDesktop;

		if (Modernizr.mq('(max-width: ' + lg + 'px)')) {
			cols = colsTablet;
		}

		if (Modernizr.mq('(max-width: ' + md + 'px)')) {
			cols = colsMobile;
		}

		for (var index = 0; index < $items.length; index = index + cols) {

			var
				$array = $items.slice(index, index + cols),
				$currentImages = $array.find($images),
				$currentCaptions = $array.find($captions),
				tl = new TimelineMax();

			tl
				.add([
					TweenMax.staggerTo($currentImages, 0.9, {
						autoAlpha: 1,
						y: '0%',
						force3D: true,
						scaleY: 1,
						ease: Power3.easeOut
					}, 0.15),
					TweenMax.staggerTo($currentCaptions, 0.9, {
						autoAlpha: 1,
						delay: 0.4,
						ease: Power3.easeOut
					}, 0.15),
				]);

			createOSScene($array[0], tl, false, true);

		}

	}

}
