/* ======================================================================== */
/* 28. FigurePortfolioAnimation */
/* ======================================================================== */
var FigurePortfolioAnimation = function ($target, animDelay = 0) {

	var
		$heading,
		$category,
		$letter,
		$imgWrapper,
		tl;

	$heading = $target.find('.figure-portfolio-big__heading');
	$category = $target.find('.figure-portfolio-big__category');
	$letter = $target.find('.figure-portfolio-big__wrapper-letter');
	$imgWrapper = $target.find('.figure-portfolio-big__wrapper-img, .figure-portfolio__wrapper-img');
	tl = new TimelineMax();

	prepare();
	animate();

	function prepare() {

		setChars($heading, 0, -20);
		setChars($category, 0, -20);

		TweenMax.set($imgWrapper, {
			y: '25%',
			autoAlpha: 0,
			force3D: true,
		});

		TweenMax.set($letter, {
			autoAlpha: 0,
			y: '200px'
		});

	}

	function animate() {

		tl
			.to($letter, 1.2, {
				y: 0,
				yPercent: 0,
				autoAlpha: 1,
				ease: Power3.easeOut,
			})
			.add([
				TweenMax.to($imgWrapper, 0.9, {
					autoAlpha: 1,
					yPercent: 0,
					force3D: true,
					y: 0,
					ease: Power3.easeOut,
				}),
			], '-=0.8')
			.add(animateChars($heading, 1.2, 0.3, Power3.easeOut), '-=0.8')
			.add(animateChars($category, 1.2, 0.3, Power3.easeOut), '-=1.2');

		createOSScene($target, tl, null, false, animDelay);

	}

}
